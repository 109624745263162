import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './store';
import { Subscriber } from './subscribers';

export enum UserStatus { ACTIVE = 'ACTIVE', INACTIVE = 'INACTIVE', DELETED = 'DELETED' };
export enum UserRole { ADMIN = 'ADMIN', COMPANY = 'COMPANY', SUPER_USER = 'SUPER_USER' };

export type User = {
  _id: string;
  email: string;
  name: string;
  phoneNumber: string;
  title: string;
  status: UserStatus;
  role: UserRole;
}

export interface Invite {
  code: string;
  email: string;
  company: User;
  subscriber?: Subscriber;
  sent: Date;
}

export type CreateUserPayload = {
  email: string;
  password?: string;
  name: string;
  phoneNumber: string;
  title: string;
  role?: UserRole;
};

export type UpdateUserPayload = Partial<CreateUserPayload> & {
  status?: UserStatus;
}

@Module({ namespaced: true, name: 'users', dynamic: true, store, preserveState: true })
class Users extends VuexModule {
  users: User[] = [];
  companies: User[] = [];
  error = '';
  loading = false;

  @Mutation
  setUsers(payload: User[]) {
    this.users = payload;
  }

  @Mutation
  setCompanies(payload: User[]) {
    this.companies = payload;
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.users = [];
    this.error = '';
    this.loading = false;
  }

  @Action
  async fetchAll() {
    this.setLoading(true);
    this.setError('');
    try {
      const response = await Vue.$axios.get('/users');
      const users = response.data.filter((user: { role: string; }) => { return user.role === 'ADMIN' });
      const companies = response.data.filter((company: { role: string; }) => { return company.role === 'COMPANY' });
      this.setUsers(users);
      this.setCompanies(companies);
    } catch (err) {
      this.setError(err.message || "Cannot fetch users");
    }
    this.setLoading(false);
  }

  @Action({ rawError: true })
  async create(data: CreateUserPayload) {
    return Vue.$axios.post('/users', data);
  }

  @Action({ rawError: true })
  async fetchOne(data: { id: string }) {
    return Vue.$axios.get(`/users/${data.id}`);
  }

  @Action({ rawError: true })
  async update(data: { id: string, data: UpdateUserPayload }) {
    return Vue.$axios.patch(`/users/${data.id}`, data.data);
  }

  @Action({ rawError: true })
  async sendInvites(data: { id: string, emails: string[] }) {
    const response = await Vue.$axios.post(`/users/${data.id}/send-invites`, { emails: data.emails });
    return response.data;
  }

  @Action({ rawError: true })
  async fetchInvites(data: { id: string }) {
    const response = await Vue.$axios.get(`/users/${data.id}/invites`);
    return response.data;
  }

  @Action({ rawError: true })
  async remove(data: { id: string }) {
    return Vue.$axios.delete(`/users/${data.id}`);
  }

  @Action
  async resetStateAction() {
    this.resetState();
  }
}

export default getModule(Users);
