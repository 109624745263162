import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import themes from '../../../config/themes.json';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes
  },
});
