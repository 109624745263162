import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './store';
import axios from 'axios';

export type Location = {
  slug: string;
  name: string;
  isVendor: boolean;
  isReturnStation: boolean;
  containers: [];
  city: string;
  streetAddress: string;
  zipCode: string;
  state: string;
  contacts: [],
  lat: number;
  lng: number;
  desc?: string;
}

export type CreateLocationPayload = {
  slug: string;
  name: string;
  containers: [];
  city: string;
  streetAddress: string;
  zipCode: string;
  state: string;
  contacts: [],
  lat: number;
  lng: number;
  isVendor: boolean;
  isReturnStation: boolean;
  desc?: string;
};

export type UpdateLocationPayload = Partial<CreateLocationPayload>;

@Module({ namespaced: true, name: 'locations', dynamic: true, store, preserveState: true })
class Locations extends VuexModule {
  vendors: Location[] = [];
  dropSites: Location[] = [];
  error = '';
  loading = false;

  @Mutation
  setVendors(payload: Location[]) {
    this.vendors = payload;
  }

  @Mutation
  setDropSites(payload: Location[]) {
    this.dropSites = payload;
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.vendors = [];
    this.dropSites = [];
    this.error = '';
    this.loading = false;
  }

  @Action
  async fetchAll() {
    this.setLoading(true);
    this.setError('');
    try {
      const response = await Vue.$axios.get('/locations');
      const vendors = response.data.filter((location: Location) => location.isVendor);
      const dropSites = response.data.filter((location: Location) => location.isReturnStation);
      this.setVendors(vendors);
      this.setDropSites(dropSites);
    } catch (err) {
      this.setError(err.message || "Cannot fetch locations");
    }
    this.setLoading(false);
  }

  @Action({ rawError: true })
  async geocodeAddress(data: { address: string }) {
    const encodedAddress = encodeURI(data.address);
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${process.env.VUE_APP_GMAPS_KEY}`);
    return response.data;
  }

  @Action({ rawError: true })
  async create(data: CreateLocationPayload) {
    return Vue.$axios.post('/locations', data);
  }

  @Action({ rawError: true })
  async fetchOne(data: { slug: string }) {
    return Vue.$axios.get(`/locations/${data.slug}`);
  }

  @Action
  async fetchContainers() {
    return Vue.$axios.get(`/containers`);
  }

  @Action({ rawError: true })
  async update(data: { slug: string, data: UpdateLocationPayload }) {
    return Vue.$axios.patch(`/locations/${data.slug}`, data.data);
  }

  @Action({ rawError: true })
  async remove(data: { slug: string }) {
    return Vue.$axios.delete(`/locations/${data.slug}`);
  }

  @Action({ rawError: true })
  async getQRCode(data: { slug: string, type?: string }) {
    const response = await Vue.$axios.get(data.type ? `/qrcode/${data.slug}?type=${data.type}` : `/qrcode/${data.slug}`, { responseType: 'arraybuffer' });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `qrcode-${data.slug}.svg`);
    document.body.appendChild(link);
    link.click();

    return response.data;
  }

  @Action
  async resetStateAction() {
    this.resetState();
  }
}

export default getModule(Locations);
