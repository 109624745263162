import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    app: {},
    auth: {},
    users: {},
    subscribers: {},
    logs: {},
    locations: {},
    transactions: {},
    plans: {}
  },
  plugins: [vuexLocal.plugin],
});
