import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './store';

export enum SubscriberStatus { 'ACTIVE', 'INACTIVE', 'DELETED' };

export type Subscriber = {
  _id: string;
  uid: string;
  name?: string;
  email?: string;
  subscriptionType?: string;
  activeReusables: number;
  status: SubscriberStatus;
  companyId?: number;
  picture?: string;
  signUpCity?: string;
  lastLogin?: Date;
}

export type Subscription = {
  _id: string;
  transactionId: any;
  subscriberId: string;
  companyId: string;
  type: string;
  issuedAt: Date;
  usageCount: number;
  active: boolean;
}

export type UpdateSubscriberPayload = {
  email: string;
  name: string;
  password: string;
  subscriptionType: string;
  activeReusables: number;
  status?: SubscriberStatus;
};

@Module({ namespaced: true, name: 'subscribers', dynamic: true, store, preserveState: true })
class Subscribers extends VuexModule {
  subscribers: Subscriber[] = [];
  error = '';
  loading = false;

  @Mutation
  setSubscribers(payload: Subscriber[]) {
    this.subscribers = payload;
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.subscribers = [];
    this.error = '';
    this.loading = false;
  }

  @Action
  async fetchAll() {
    this.setLoading(true);
    this.setError('');
    try {
      const response = await Vue.$axios.get('/subscribers');
      this.setSubscribers(response.data);
    } catch (err) {
      this.setError(err.message || "Cannot fetch subscribers");
    }
    this.setLoading(false);
  }

  @Action({ rawError: true })
  async fetchOne(data: { id: string }) {
    return Vue.$axios.get(`/subscribers/${data.id}`);
  }

  @Action({ rawError: true })
  async fetchSubscriptions(data: { id: string }) {
    return Vue.$axios.get(`/subscribers/${data.id}/subscriptions`);
  }

  @Action({ rawError: true })
  async update(data: { id: string, data: UpdateSubscriberPayload }) {
    return Vue.$axios.patch(`/subscribers/${data.id}`, data.data);
  }

  @Action({ rawError: true })
  async remove(data: { id: string }) {
    return Vue.$axios.delete(`/subscribers/${data.id}`);
  }

  @Action
  async resetStateAction() {
    this.resetState();
  }
}

export default getModule(Subscribers);
