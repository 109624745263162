import axios from 'axios';
import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './store';

import Locations from './locations';
import Logs from './logs';
import Subscribers from './subscribers';
import Users from './users';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:3000';

export type User = {
  _id: string;
  email: string;
  name: string;
  disabled: boolean;
}

@Module({ namespaced: true, name: 'auth', dynamic: true, store, preserveState: true })
class Auth extends VuexModule {
  user: User | {} = {};
  error = '';
  token = '';
  offline = false;
  loading = false;

  @Mutation
  setUser(payload: User) {
    this.user = Object.assign({}, payload);
  }

  @Mutation
  removeUser() {
    this.user = {};
    this.token = '';
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setToken(payload: string) {
    this.token = payload;
  }

  @Mutation
  removeToken() {
    this.token = '';
  }

  @Mutation
  setOffline(payload: boolean) {
    this.offline = payload;
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.user = {};
    this.error = '';
    this.token = '';
    this.offline = false;
  }

  get loggedIn() {
    return !!this.token;
  }

  @Action
  async doLogin(data: { email: string; password: string }) {
    this.setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/auth/login`, data);
      const responseData = response.data;

      if (!responseData) {
        throw new Error('Server error');
      }

      this.setUser(responseData.user);
      this.setError('');
      this.setToken(responseData.token);
      await Locations.resetStateAction();
      await Logs.resetStateAction();
      await Subscribers.resetStateAction();
      await Users.resetStateAction();
      document.location.href = '/';
    } catch (err) {
      const errorMessage = (err.response && err.response.data && err.response.data.message)
        || err.message;
      this.setError(errorMessage);
      // throw new Error(errorMessage);
    }
    this.setLoading(false);
  }

  @Action
  async doRegister(data: { email: string; name: string, password: string }) {
    this.setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/auth/register`, data);
      const responseData = response.data;

      if (!responseData) {
        throw new Error('Server error');
      }

      this.setError('');
    } catch (err) {
      const errorMessage = (err.response && err.response.data && err.response.data.message)
        || err.message;
      this.setError(errorMessage);
      // throw new Error(errorMessage);
    }
    this.setLoading(false);
  }

  @Action
  async doLogout() {
    this.removeUser();
    this.removeToken();
    this.setError('');
    window.location.href = '/login';
    // dispatch('reset', {}, { root: true });
  }

  @Action
  async update(data: { fields: Partial<User> }) {
    const userId = (this.user as User)._id;
    if (!userId) {
      throw new Error('Not logged in');
    }
    const response = await Vue.$axios.put('/account/update', {
      ...data.fields,
    });

    const responseData = response.data;

    if (!responseData) {
      throw new Error('Server error');
    }

    this.setUser({
      ...responseData,
    });
  }

  @Action({ rawError: true })
  async resetPassword(data: { email: string }) {
    await axios.post(`${API_URL}/users/${data.email}/reset-password`);
  }

  @Action({ rawError: true })
  async resetPasswordWithCode(data: { email: string, code: string }) {
    try {
      await axios.post(`${API_URL}/users/reset-password`, data);
    } catch (err) {
      const errorMessage = (err.response && err.response.data && err.response.data.message)
        || err.message;
      
      throw Error(errorMessage);
    }
  }

  @Action
  async resetStateAction() {
    this.resetState();
  }
}

export default getModule(Auth);
