





import Vue from 'vue';
import Component from 'vue-class-component';

import Default from '@/layouts/Default.vue';
import centered from '@/layouts/Centered.vue';

@Component
export default class extends Vue {
  layouts: any = {
    Default,
    centered
  }

  get layout() {
    return this.$route.meta && this.$route.meta.layout;
  }

  get layoutComponent() {
    if (this.layout) {
      return this.layouts[this.layout];
    }

    return this.layouts.Default;
  }
}
