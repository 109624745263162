import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './store';

export type Log = {
  _id: string;
  email: string;
  name: string;
}

@Module({ namespaced: true, name: 'logs', dynamic: true, store, preserveState: true })
class Logs extends VuexModule {
  logs: Log[] = [];
  error = '';
  loading = false;

  @Mutation
  setLogs(payload: Log[]) {
    this.logs = payload;
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.logs = [];
    this.error = '';
    this.loading = false;
  }

  @Action
  async fetchAll() {
    this.setLoading(true);
    this.setError('');
    try {
      const response = await Vue.$axios.get('/logs');
      this.setLogs(response.data);
    } catch (err) {
      this.setError(err.message || "Cannot fetch logs");
    }
    this.setLoading(false);
  }

  @Action
  async resetStateAction() {
    this.resetState();
  }
}

export default getModule(Logs);
