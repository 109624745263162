













































import Vue from "vue";
import Component from "vue-class-component";
import auth from "@/store/auth";
import App from "@/store/app";
import config from '../lib/config';

@Component({ name: "Layout" })
export default class extends Vue {
  drawer: boolean = true;
  menu: any[] = [
    { text: "Home", icon: "mdi-home", to: "/" },
    { text: "Subscribers", icon: "mdi-account-multiple", to: "/subscribers" },
    { text: "Subscription Plans", icon: "mdi-playlist-check", to: "/plans" },
    { text: "Vendors", icon: "mdi-map-marker-radius", to: "/locations/vendors" },
    { text: "Return Stations", icon: "mdi-map-marker-down", to: "/locations/return-stations" },
    { text: "Companies", icon: "mdi-briefcase", to: "/companies" },
    { text: "Users", icon: "mdi-account-cog", to: "/users" },
    { text: "Logs", icon: "mdi-format-list-checkbox", to: "/logs" }
    // { text: 'Settings', icon: 'mdi-cogs', to: '/settings' }
  ];

  doLogout() {
    auth.doLogout();
  }

  get name() {
    return (auth.user as any).name;
  }

  get email() {
    return (auth.user as any).email;
  }

  get title() {
    return (this.$route.meta && this.$route.meta.title) || config.appName;
  }

  get snackbar() {
    return App.snackbar;
  }

  set snackbar(value: boolean) {
    App.setSnackbar(value);
  }

  get snackbarMessage() {
    return App.snackbarMessage;
  }

  get snackbarDelay() {
    return App.snackbarDelay;
  }

  hideSnackbar() {
    App.hideSnackbar();
  }
}
