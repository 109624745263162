




















import Vue from "vue";
import Component from "vue-class-component";
import auth from "../store/auth";

@Component
export default class Home extends Vue {
  email = "";
  password = "";
  loading = false;
  error = "";

  mounted() {
    this.$forceUpdate();
  }

  async doLogin() {
    this.loading = true;
    try {
      await auth.doLogin({
        email: this.email,
        password: this.password
      });
    } catch (err) {
      this.error = err.message;
    }
    this.loading = false;
  }
}
