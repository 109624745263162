import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import auth from '@/store/auth';
import config from '../config.json';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true,
      layout: 'centered'
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'),
    meta: {
      public: true,
      layout: 'centered'
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import(/* webpackChunkName: "reset" */ '../views/ResetPasswordCallback.vue'),
    meta: {
      public: true,
      layout: 'centered'
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users/UsersList.vue'),
    meta: {
      title: 'Users'
    }
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: () => import(/* webpackChunkName: "usersCreate" */ '../views/Users/UsersEdit.vue'),
    meta: {
      title: 'Create User'
    }
  },
  {
    path: '/users/:id',
    name: 'users-edit',
    component: () => import(/* webpackChunkName: "usersEdit" */ '../views/Users/UsersEdit.vue'),
    meta: {
      title: 'Edit User'
    }
  },
  {
    path: '/subscribers',
    name: 'subscribers',
    component: () => import(/* webpackChunkName: "subscribers" */ '../views/Subscribers/SubscribersList.vue'),
    meta: {
      title: 'Subscribers'
    }
  },
  {
    path: '/subscribers/details/:id',
    name: 'subscriber-details',
    component: () => import(/* webpackChunkName: "subscriberView" */ '../views/Subscribers/SubscriberView.vue'),
    meta: {
      title: 'Subscriber details'
    }
  },
  {
    path: '/subscribers/edit/:id',
    name: 'subscriber-edit',
    component: () => import(/* webpackChunkName: "subscribersEdit" */ '../views/Subscribers/SubscribersEdit.vue'),
    meta: {
      title: 'Edit Subscriber'
    }
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import(/* webpackChunkName: "logs" */ '../views/Logs/LogsList.vue'),
    meta: {
      title: 'Logs'
    }
  },
  {
    path: '/locations/vendors',
    name: 'vendors',
    component: () => import(/* webpackChunkName: "vendors" */ '../views/Locations/VendorList.vue'),
    meta: {
      title: 'Vendors'
    }
  },
  {
    path: '/locations/return-stations',
    name: 'return-stations',
    component: () => import(/* webpackChunkName: "dropsites" */ '../views/Locations/ReturnStationList.vue'),
    meta: {
      title: 'Return Stations'
    }
  },
  {
    path: '/locations/create/:type',
    name: 'locations-create',
    component: () => import(/* webpackChunkName: "locationsCreate" */ '../views/Locations/LocationsEdit.vue'),
    meta: {
      title: 'Create Location'
    }
  },
  {
    path: '/locations/details/:slug',
    name: 'locations-details',
    component: () => import(/* webpackChunkName: "locationView" */ '../views/Locations/LocationView.vue'),
    meta: {
      title: 'Location details'
    }
  },
  {
    path: '/locations/edit/:type/:slug',
    name: 'locations-edit',
    component: () => import(/* webpackChunkName: "locationsEdit" */ '../views/Locations/LocationsEdit.vue'),
    meta: {
      title: 'Edit Location'
    }
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import(/* webpackChunkName: "companies" */ '../views/Companies/CompaniesList.vue'),
    meta: {
      title: 'Companies'
    }
  },
  {
    path: '/companies/create',
    name: 'company-create',
    component: () => import(/* webpackChunkName: "company-view" */ '../views/Companies/CompanyView.vue'),
    meta: {
      title: 'New Company'
    }
  },
  {
    path: '/companies/:id',
    name: 'company-view',
    component: () => import(/* webpackChunkName: "company-view" */ '../views/Companies/CompanyView.vue'),
    meta: {
      title: 'Company Details'
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import(/* webpackChunkName: "transactions" */ '../views/Transactions/TransactionsList.vue'),
    meta: {
      title: 'Transactions'
    }
  },
  {
    path: '/plans',
    name: 'plans',
    component: () => import(/* webpackChunkName: "plans" */ '../views/Plans/PlansList.vue'),
    meta: {
      title: 'Subscription Plans'
    }
  },
  {
    path: '/plans/create',
    name: 'plans',
    component: () => import(/* webpackChunkName: "plans" */ '../views/Plans/PlanEdit.vue'),
    meta: {
      title: 'Create Subscription Plan'
    }
  },
  {
    path: '/plans/:id',
    name: 'plans',
    component: () => import(/* webpackChunkName: "plans" */ '../views/Plans/PlanEdit.vue'),
    meta: {
      title: 'Edit Subscription Plan'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const authRequired = !(to.meta && to.meta.public);
  const loggedIn = auth.loggedIn;

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (to.name === 'login' && loggedIn) {
    return next('/');
  }

  const title = (to.meta && to.meta.title)
  document.title = title ? `${config.appName} | ${title}` : config.appName;

  next();
});

export default router
