/* eslint-disable no-shadow */
import Vue, { PluginObject } from 'vue';
import axios from 'axios';
import auth from '@/store/auth';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const config = {
  baseURL:
    process.env.VUE_APP_API_URL
    || 'http://localhost:3000',
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

// eslint-disable-next-line no-underscore-dangle
const _axios = axios.create(config);

_axios.interceptors.request.use(
  (cfg) => {
    const token = auth.token;

    if (token) {
      // eslint-disable-next-line no-param-reassign
      cfg.headers.Authorization = `Bearer ${token}`;
    }

    return cfg;
  },
  err =>
    // Do something with request error
    // eslint-disable-next-line implicit-arrow-linebreak
    Promise.reject(err)
  ,
);

// Add a response interceptor
_axios.interceptors.response.use(
  (res) => {
    auth.setOffline(false);
    // Do something with response data
    return res;
  },
  (err) => {
    if (!err.response) {
      auth.setOffline(true);
      return Promise.reject(
        new Error('Cannot connect to server'),
      );
    }

    if (err.response && err.response.status === 401) {
      auth.doLogout();
      document.location.href = '/';
    }

    if (err.response && err.response.data && err.response.data.message) {
      return Promise.reject(new Error(err.response.data.message));
    }
    // Do something with response error
    return Promise.reject(err);
  },
);

const Plugin: PluginObject<any> = {
  install: (Vue) => {
    // eslint-disable-next-line no-param-reassign
    Vue.$axios = _axios;
  },
};
Plugin.install = (Vue) => {
  // eslint-disable-next-line no-param-reassign
  Vue.$axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
